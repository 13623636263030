import React from "react";
import { getMediaType, MediaType } from "../../helpers/mediaTypes";
import { Audio } from "./types/audio";
import { Image } from "./types/image";
import { Video } from "./types/video";

interface OwnProps {
  media: any;
}

export const Preview: React.FC<OwnProps> = ({ media }) => {
  const extension = media.previewDetails.previewType;
  const type = getMediaType(extension);

  switch (type) {
    case MediaType.Video:
      return <Video data={media} />;
    case MediaType.Image:
      return <Image data={media} />;
    case MediaType.Audio:
      return <Audio data={media} />;
    default:
      return null;
  }
};
