import React from "react";
import { makeStyles } from "@material-ui/core";
import { NavMain } from "./partials/nav-main";
import { Branding } from "./partials/branding/branding";
import { ActionBar } from "./partials/actionbar/actionbar";

interface ownProps {
  title?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    top: 0,
    left: 0,
    right: 0,
    position: "fixed",
    zIndex: 100,
    padding: theme.spacing(3, 3, 0, 3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 2, 0, 2),
    }
  },

  header: {
    flexGrow: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  line: {
    height: 1,
    backgroundColor: "#D5D5D5",
    [theme.breakpoints.down("sm")]: {
      height: 0,
    },
  },

  headerContainer: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    maxWidth: "unset",
    flex: 1,
    padding: 1,
    marginBottom: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    }
  },

  actionbarContainer: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    maxWidth: "unset",
  },
}));

function Header(props: ownProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <div className={classes.headerContainer}>
          <Branding></Branding>
          <NavMain></NavMain>
        </div>
      </header>
      <div>
        <div className={classes.actionbarContainer}>
          <ActionBar></ActionBar>
        </div>
      </div>
      <div className={classes.line}></div>
    </div>
  );
}

export default Header;
