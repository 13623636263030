import { makeStyles, Button, Dialog, DialogContent } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { RequireAuth } from '../../auth/require-auth';
import { UnauthorizedButtons } from './unauthorized-buttons';
// import Cookies from 'js-cookie';
import { get } from 'lodash';
import { RequestFileForm } from '../form/request-file';
import { PopupHeader } from '../../popup-header';
import { DownloadFileForm } from '../form/download-terms';

interface OwnProps {
  media: any;
}

const useStyles = makeStyles((theme) => ({
  button: { marginBottom: theme.spacing(1) },
}));

export const ActionButtons: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  const onRequest = get(props, 'media.details.onRequest');
  const [dialog, setDialogState] = useState<{
    type: 'request' | 'download' | undefined;
    open: boolean;
  }>({ type: undefined, open: false });

  const setDialog = useCallback((type: 'request' | 'download' | undefined) => {
    setDialogState({ type, open: type ? true : false });
  }, []);

  return (
    <div>
      <RequireAuth
        unauthorizedComponent={() => {
          return <UnauthorizedButtons />;
        }}
      >
        {onRequest ? (
          <Button
            id="btn-request-file"
            variant="contained"
            color="primary"
            onClick={() => setDialog('request')}
            className={classes.button}
          >
            Aanvragen
          </Button>
        ) : (
          <Button
            id="btn-download-file"
            variant="contained"
            color="primary"
            onClick={() => setDialog('download')}
            className={classes.button}
          >
            Downloaden
          </Button>
        )}

        <Dialog
          id="dialog-request-file"
          PaperProps={{
            style: {
              width: 480,
              borderRadius: 0,
              paddingBottom: 20,
            },
          }}
          open={dialog.open}
          onClose={() => {
            setDialog(undefined);
          }}
        >
          <DialogContent>
            {dialog.type === 'request' && (
              <>
                <PopupHeader title={'Bestand aanvragen'} handleClose={() => setDialog(undefined)} />
                <RequestFileForm media={props.media} />
              </>
            )}

            {dialog.type === 'download' && (
              <>
                <PopupHeader title={'Downloaden'} handleClose={() => setDialog(undefined)} />
                <DownloadFileForm media={props.media} />
              </>
            )}
          </DialogContent>
        </Dialog>
      </RequireAuth>
    </div>
  );
};
