import React, { useState } from 'react';
import { makeStyles, Button, TextField, Link } from '@material-ui/core';

import { Loading } from '../../loading';
import { useAuth } from '../../../../hooks/use-auth';
import { AuthState } from '../../../services/auth';

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: '0.9em',
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(1),
    display: 'block',
  },
  textField: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    width: '100%',
    border: '1px solid #ccc',
    padding: '4px',
  },
  formInputBase: {
    color: '#000000',
    input: {
      textTransform: 'lowercase',
    },
  },
  action: {
    padding: 0,
  },
  loginButton: {
    marginTop: theme.spacing(1),
    marginRight: 0,
  },
  message: {
    marginTop: theme.spacing(1),
    padding: '5px 10px',
    backgroundColor: 'red',
    color: '#fff',
  },
}));

interface OwnProps {}

export const LoginForm: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  const submitForm = React.useCallback(
    (e: any) => {
      e.preventDefault();

      setLoading(true);
      auth.signIn(username, password).then(() => {
        setLoading(false);
      });

      return;
    },
    [username, password, auth]
  );

  if (loading) {
    return <Loading contained={true} />;
  }

  return (
    <>
      <form onSubmit={submitForm}>
        <TextField
          fullWidth
          variant="outlined"
          error={auth.error && auth.error.key === 'empty_username' ? true : false}
          required
          className={classes.textField}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          label="Naam"
          name="username"
        />

        <TextField
          fullWidth
          variant="outlined"
          error={auth.error && auth.error.key === 'empty_password' ? true : false}
          required
          className={classes.textField}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          name="password"
          label="Wachtwoord"
        />
        <div>
          <Link
            style={{ cursor: 'pointer' }}
            onClick={() => {
              auth.setState(AuthState.RequestNewPassword);
            }}
          >
            <small>Wachtwoord vergeten?</small>
          </Link>
        </div>
        {auth.error ? <div className={classes.message}>{auth.error.message}</div> : ''}

        <Button
          id="btn-login-submit"
          type="submit"
          className={classes.loginButton}
          variant="contained"
          color="primary"
          onClick={submitForm}
          disabled={!username || !password}
        >
          Inloggen
        </Button>
      </form>
    </>
  );
};
