import React from "react";
import { makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.secondary.main,
    },
    link:{
        display: "inline-flex",
        flex: 0,
        alignItems: "center",
        textDecoration: "none",
        textTransform: "uppercase",
        fontFamily: "'TradeGothicLT-BoldCondTwenty', sans-serif",
        fontWeight: 400,
        paddingRight: theme.spacing(2),
        "&:hover $icon":{
            transform: "translateX(-10px)",
        }
    },
    icon: {
        marginRight: theme.spacing(.5),
        transform: "translateX(0)",
        transition: ".3s all",
        fontSize: "1em",
        marginTop: -1.5
    }
}))

interface OwnProps{}

export const BackButton: React.FC<OwnProps> = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Link to="/" className={classes.link}>
                <ArrowBackIcon className={classes.icon} /> Terug naar overzicht
            </Link>
        </div>
    )
}