import { setContext } from '@apollo/client/link/context';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { Auth } from '../view/services/auth';

const token = setContext(async (_, { headers }) => {
  const accessToken = await Auth.getAccessToken();

  if (!accessToken) {
    return headers;
  }

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: token.concat(
    createHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_API,
      credentials: 'include',
    })
  ),
  cache: new InMemoryCache(),
});

export const tokenClient = new ApolloClient({
  link: createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_API,
    credentials: 'include',
  }),
  cache: new InMemoryCache(),
});
