import React, { useState } from 'react';
import { makeStyles, Button, TextField } from '@material-ui/core';

import { useAuth } from '../../../../hooks/use-auth';

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: '0.9em',
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(1),
    display: 'block',
  },
  textField: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    width: '100%',
    border: '1px solid #ccc',
    padding: '4px',
  },
  formInputBase: {
    color: '#000000',
    input: {
      textTransform: 'lowercase',
    },
  },
  action: {
    padding: 0,
  },
  loginButton: {
    marginTop: theme.spacing(1),
    marginRight: 0,
  },
  message: {
    marginTop: theme.spacing(1),
    padding: '5px 10px',
    backgroundColor: 'red',
    color: '#fff',
  },
}));

interface OwnProps {}

export const RequestNewPasswordForm: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const [username, setPassword] = useState('');
  const [submitting, isSubmitting] = React.useState(false);

  const requestNewPassword = React.useCallback(
    async (e) => {
      e.preventDefault();
      isSubmitting(true);
      await auth.requestNewPassword(username);
      isSubmitting(false);
      return;
    },
    [username, auth]
  );

  return (
    <>
      <form onSubmit={requestNewPassword}>
        <TextField
          fullWidth
          type="username"
          variant="outlined"
          required
          className={classes.textField}
          value={username}
          onChange={(e) => setPassword(e.target.value)}
          label="Gebruikersnaam"
          name="username"
        />

        {auth.error ? <div className={classes.message}>{auth.error.message}</div> : ''}

        <Button
          id="btn-request-password-submit"
          type="submit"
          className={classes.loginButton}
          variant="contained"
          color="primary"
          onClick={requestNewPassword}
          disabled={submitting || !username}
        >
          Wachtwoord aanvragen
        </Button>
      </form>
    </>
  );
};
