import React from "react";
import { makeStyles } from "@material-ui/core";
import { Title } from "./title";
import { Logo } from "./logo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    gap: 12,
    flexDirection: "row",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {},
  },
}));

interface OwnProps {
  title?: string;
}

export const Branding: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Logo></Logo>
      <Title></Title>
    </div>
  );
};
