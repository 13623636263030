import gql from "graphql-tag";

export const Categories = gql`
  query CategoryMenu {
    menu(id: "categorie_navigatie", idType: NAME) {
      id
      name
      menuItems(first: 100) {
        nodes {
          key: id
          id
          label
          parentId
          connectedNode {
            node {
              ... on Category {
                id
                name
                databaseId
                slug
              }
            }
          }
          databaseId
        }
      }
      count
    }
  }
`;
