import React from "react";
import { makeStyles, Grid, Hidden } from "@material-ui/core";
import Filters from "./categories";
import Search from "./search";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  filterRoot: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    height: "100%",
  },

  appBar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    paddingLeft: 0,
    flexDirection: "row",
  },
  toolBar: {
    paddingLeft: 0,
    minHeight: "unset",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  search: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

interface OwnProps {
  title?: string;
}

export const ActionBar: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={11} md={3}>
        <Search />
      </Grid>
      <Hidden smDown>
        <Grid item xs={1} md={9}>
          <div className={classes.filterRoot}>
            <Filters></Filters>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  );
};
