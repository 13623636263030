import { useQuery } from '@apollo/client';
import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router';
import * as Query from '../../gql/allmediaobjects';
import { useFilters } from '../../hooks/use-filters';
import { FilterResults } from '../components/gallery-filtering/partials/filter-results';
import { SelectedFilters } from '../components/gallery-filtering/selected-filters';
import { GalleryItem } from '../components/gallery/gallery-item';
import { Loading } from '../components/loading';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  resultsBar: {
    display: 'flex',
    alignItems: 'center',
  },
  gridItem: {
    padding: '2px 4px !important',
    [theme.breakpoints.up('md')]: {
      padding: '6px !important',
    },
  },
  btnContainer: {
    display: 'flex',
    padding: theme.spacing(3, 0),
    justifyContent: 'center',
  },
  loadMoreBtn: {},
}));

interface OwnProps extends RouteComponentProps<{ cat: string }> {}

export const PageOverview: React.FC<OwnProps> = (props) => {
  const { state } = useFilters();
  const classes = useStyles();
  const params = useParams();
  const { service } = useFilters();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    service.initParams(params);
    setLoaded(false);
  }, [params, service]);

  const variables: any = {
    first: 32,
    after: '',
    where: {
      search: state.search,
      categoryIn: state.categories.map((cat) => cat.id),
      orderby: [
        {
          field: 'DATE',
          order: 'DESC',
        },
      ],
    },
  };

  const { data, fetchMore, loading } = useQuery(Query.MediaObjects, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  const items = (data && data.mediaobjects && data.mediaobjects.nodes) || [];

  return (
    <>
      <div className={classes.resultsBar}>
        <FilterResults value={(data && data.mediaobjects.pageInfo.total) || 0} />
        <SelectedFilters />
      </div>
      <Grid container spacing={1} className={classes.root}>
        {items.map((data: any, i: number) => (
          <Grid key={i} item xs={'auto'} className={classes.gridItem}>
            <GalleryItem data={data} />
          </Grid>
        ))}
      </Grid>
      <div className={classes.btnContainer}>
        {(!loaded && loading && <Loading />) || (
          <Button
            variant="contained"
            color="primary"
            className={classes.loadMoreBtn}
            disabled={data && !data.mediaobjects.pageInfo.hasNextPage}
            onClick={() => {
              setLoaded(true);
              fetchMore({
                variables: {
                  after: data && data.mediaobjects.pageInfo.endCursor,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return;
                  const nodes = [...prev.mediaobjects.nodes, ...fetchMoreResult.mediaobjects.nodes];
                  const merged = {
                    ...prev.mediaobjects,
                    ...fetchMoreResult.mediaobjects,
                  };

                  merged.nodes = nodes;

                  return {
                    mediaobjects: merged,
                  };
                },
              });
            }}
          >
            Toon meer resultaten
          </Button>
        )}
      </div>
    </>
  );
};
