import "./theme/css/fonts.css";
import "./theme/css/app.css";

import React from "react";
import { render } from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import theme from "./theme/theme";
import { client } from "./apollo/apolloClient";
import { App } from "./view/app";
import { AuthProvider } from "./view/providers/auth-provider";

export const Index: React.FC = () => {
  // disable right mouse button
  document.addEventListener("contextmenu", (event) => event.preventDefault());

  return (
    <AuthProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </ApolloProvider>
    </AuthProvider>
  );
};

render(<Index />, document.getElementById("root"));
