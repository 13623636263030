import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "auto",
    height: 50,
    background: theme.palette.primary.main,
    position: "absolute",
    bottom: -50,
    right: 0,
    zIndex: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    cursor: "pointer",
    transition: "all 0.5s",
    fontSize: "1.1em",
  },
  buttonInview: {
    bottom: 0,
  },
  buttonText: {
    color: "#fff",
    textDecoration: "none",
    textTransform: "uppercase",
  },
  link: {
    textDecoration: "none",
  },
}));

interface OwnProps {
  data: any;
  show?: boolean;
}

export const ItemButton: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const show = props.show;

  return (
    <div
      className={clsx({
        [classes.button]: true,
        [classes.buttonInview]: show,
      })}
    >
      <Typography className={classes.buttonText} component="h3">
        info
      </Typography>
    </div>
  );
};
