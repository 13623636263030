import { useContext } from "react";
import { AuthContext, Auth } from "../view/services/auth";

export const useAuth = () => {
  useAuthState();
  return Auth;
};

export const useAuthUser = (): any => {
  const context = useContext(AuthContext);
  return context.user;
};

export const useAuthState = (): any => {
  const context = useContext(AuthContext);
  return context.state;
};
