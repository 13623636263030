import React from 'react';
import { makeStyles, Link, Typography } from '@material-ui/core';
import { ReactComponent as FacebookIcon } from '../../../../assets/images/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../../assets/images/instagram.svg';
import { ReactComponent as LinkedinIcon } from '../../../../assets/images/linkedin.svg';
import { ReactComponent as YoutubeIcon } from '../../../../assets/images/youtube.svg';
import { ReactComponent as PinterestIcon } from '../../../../assets/images/pinterest.svg';

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    lineHeight: '2em',
  },
  link: {
    color: theme.palette.secondary.contrastText,
    fontSize: '1em',
    fontWeight: 300,
  },
  socialMedia: {
    margin: '24px 0',
  },
  socialButton: {
    border: '1px solid',
    width: 24,
    height: 24,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    margin: 4,
  },
}));

interface OwnProps {}

export const NavContact: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <nav className={classes.nav}>
      <Typography gutterBottom variant={'h5'}>
        Contact
      </Typography>
      Langestraat 41
      <br />
      7511 HB Enschede
      <br />
      <Link className={classes.link} href={'tel:+31534801970'}>
        +31 53 480 19 70
      </Link>
      <Link className={classes.link} href={'mailto:info@enschedepromotie.nl'}>
        info@enschedepromotie.nl
      </Link>
      <div className={classes.socialMedia}>
        <Link className={classes.socialButton} href={'https://www.facebook.com/Enschede/'}>
          <FacebookIcon />
        </Link>
        <Link className={classes.socialButton} href={'https://www.instagram.com/enschede'}>
          <InstagramIcon />
        </Link>
        <Link
          className={classes.socialButton}
          href={'https://www.linkedin.com/company/enschede-promotie/'}
        >
          <LinkedinIcon />
        </Link>
        <Link
          className={classes.socialButton}
          href={'https://www.youtube.com/channel/UCFC7Bmt5V6XVShWxk9j2RGw'}
        >
          <YoutubeIcon />
        </Link>
        <Link className={classes.socialButton} href={'https://nl.pinterest.com/enschede/'}>
          <PinterestIcon />
        </Link>
      </div>
    </nav>
  );
};
