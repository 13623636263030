import React from 'react';
import { useLoading, Rings } from '@agney/react-loading';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff',
    display: 'flex',
    left: 0,
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
  },
  contained: {
    backgroundColor: '#fff',
    display: 'flex',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
  },
}));

interface OwnProps {
  contained?: any;
}

export const Loading: React.FC<OwnProps> = (props) => {
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    // @ts-ignore
    indicator: (<Rings width="100" color="#ee272c" />) as any,
  });

  const classes = useStyles();
  return (
    <div className={props.contained ? classes.contained : classes.root}>
      <section {...containerProps}>
        {indicatorEl} {/* renders only while loading */}
      </section>
    </div>
  );
};
