import React from "react";
import { Link, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
      marginTop: -4,
    },
    marginTop: "-8px",
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: "2.1em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3em",
    },
  },
  link: {
    fontSize: ".8em",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

interface OwnProps {
  title?: string;
}

export const Title: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h3" component="h1">
        Mediabank
      </Typography>

      <Link href="https://enschedepromotie.nl" className={classes.link}>
        Terug naar enschedepromotie.nl
      </Link>
    </div>
  );
};
