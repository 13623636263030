import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Tags } from "./partials/tags";

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: "160%",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  attr: {
    flex: "0.5",
  },
  attrName: {
    fontFamily: "TradeGothicLT-BoldCondTwenty",
    fontWeight: 400,
  },
  title: {
    fontSize: "1.7em",
    [theme.breakpoints.up("md")]: {
      fontSize: "2.3em",
    },
  },
}));

interface OwnProps {
  media: any;
}

interface MediaObjectAttribute {
  key: string;
  value: string;
  name?: string;
  desctiption?: string;
}

export const MediaDetails: React.FC<OwnProps> = ({ media }) => {
  const classes = useStyles();
  const info = (media && media.mediaInfo) || [];
  const mediaObjectAttributesList: MediaObjectAttribute[] = info;

  // helpers
  const mediaObjectAttributes = mediaObjectAttributesList.reduce((record, item) => {
    record[item.key] = item;
    return record;
  }, {} as any);

  return (
    <>
      {media.content && (
        <Typography
          variant={"body1"}
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: media.content,
          }}
        ></Typography>
      )}
      <div className={classes.root}>
        {Object.values(mediaObjectAttributes)
          .filter((attr: any) => {
            return attr.value !== "null" && attr.value !== null && attr.value;
          })
          .map((attr: any, index: number) => {
            return (
              <Typography className={classes.attr} key={index}>
                <strong className={classes.attrName}>{attr.name}</strong> : {attr.value}
              </Typography>
            );
          })}
      </div>
      <Tags media={media} />
    </>
  );
};
