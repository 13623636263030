import { Dialog, DialogContent, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { useAuth } from "../../../hooks/use-auth";
import { AuthState } from "../../services/auth";
import { PopupHeader } from "../popup-header";
import { ResetPasswordForm } from "./forms/reset-password-form";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    padding: theme.spacing(2, 0),
    margin: theme.spacing(2, 2, 0, 2),
    borderTop: "1px solid #ccc",
  },
  footerText: {
    fontSize: "0.8em",
  },

  link: {
    cursor: "pointer",
  },
}));

interface OwnProps {}

export const ResetPassword: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const history = useHistory();

  const close = React.useCallback(() => {
    history.push(window.location.pathname);
    auth.setState(AuthState.SignedOut);
  }, [auth, history]);

  return (
    <Dialog
      PaperProps={{
        style: {
          width: 380,
          borderRadius: 0,
        },
      }}
      open={auth.state === AuthState.ResetPassword}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <PopupHeader title={"Maak wachtwoord"} handleClose={close} />
        <ResetPasswordForm />
      </DialogContent>
      <div className={classes.footer}>
        <Link onClick={close} className={classes.link}>
          <Typography>Terug naar inloggen</Typography>
        </Link>
      </div>
    </Dialog>
  );
};

export default ResetPassword;
