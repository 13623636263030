import { makeStyles, Chip } from '@material-ui/core';
import React from 'react';
import { useFilters } from '../../../../hooks/use-filters';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    flexDirection: 'column',
  },
  tags: {
    display: 'inline-flex',
    flexWrap: 'wrap',
  },
  title: {
    display: 'inline-flex',
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontFamily: 'TradeGothicLT-BoldCondTwenty',
    fontWeight: 400,
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  chipItem: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    '&:first-of-type': {
      marginLeft: 0,
    },
  },
}));

interface OwnProps {
  media: any;
}

export const Tags: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const { service } = useFilters();
  const categories = (props.media && props.media.categories && props.media.categories.nodes) || [];
  const tags = (props.media && props.media.mediaTags && props.media.categories.nodes) || [];
  const items = categories.concat(tags);
  const uniqueArray = items.filter(function(item: any, pos: any) {
    return items.indexOf(item) === pos;
  });

  return (
    items.length && (
      <div className={classes.root}>
        <div className={classes.title}>Categories &amp; tags</div>

        {uniqueArray.map((data: any) => {
          let icon;

          return (
            <span className={`${classes.chipItem} cat-tag-mediaobject`} key={data.id}>
              <Chip
                variant={'outlined'}
                icon={icon}
                label={data.name}
                onClick={() => {
                  service.setCategory({ id: data.databaseId, slug: data.slug, label: data.name });
                }}
              />
            </span>
          );
        })}
      </div>
    )
  );
};
