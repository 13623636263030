import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import logo from "../../../../../images/logo.svg";

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: 100,
    [theme.breakpoints.up("md")]: {
      maxWidth: 156,
    },
    cursor: "pointer",
  },
}));

interface OwnProps {
  title?: string;
}

export const Logo: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <Link to={"/"}>
      <img alt={"Enschede Promotie"} className={classes.logo} src={logo} />
    </Link>
  );
}
