import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import NavigateNextOutlined from "@material-ui/icons/NavigateNextOutlined";
import NavigateBefore from "@material-ui/icons/NavigateBefore";

const useStyles = makeStyles((theme) => ({
  root: { display: "inline-flex" },
  paginate: {
    display: "inline-flex",
    marginLeft: theme.spacing(2),
    alignItems: "center",
  },
  next: {
    marginLeft: theme.spacing(0.25),
    display: "inline-flex",
  },
  prev: {
    display: "inline-flex",
  },
  icon: {
    cursor: "pointer",
    borderStyle: "solid",
    borderWidth: "1px",
    fontSize: "1.2rem",
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  text: {
    marginTop: 4,
    fontWeight: 500,
    fontSize: "1em",
    textTransform: "uppercase",
    color: theme.palette.secondary.main,
    fontFamily: "'TradeGothicLT-BoldCondTwenty', sans-serif",
  },
}));

interface OwnProps {
  data?: any;
  active: boolean;
  perPage: number;
  onChange(page: number): any;
}

export const FilterPaginate: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  const perPage = props.perPage;
  const pageCount = props.data.mediaobjects.nodes.length / perPage;
  const [page, setPage] = useState(1);

  if (props.active) {
    return (
      <div className={classes.root}>
        <Typography className={classes.text}>
          {perPage} per pagina - pagina {page}
        </Typography>
        <div className={classes.paginate}>
          {page > 1 ? (
            <div className={classes.prev}>
              <NavigateBefore
                onClick={() => {
                  setPage(page - 1);
                  props.onChange(page - 1);
                }}
                className={classes.icon}
              />
            </div>
          ) : (
            <></>
          )}

          {page < pageCount ? (
            <div className={classes.next}>
              <NavigateNextOutlined
                onClick={() => {
                  setPage(page + 1);
                  props.onChange(page + 1);
                }}
                className={classes.icon}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
