import { makeStyles, Chip } from "@material-ui/core";
import React from "react";
import { useFilters } from "../../../hooks/use-filters";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    marginLeft: theme.spacing(2),
  },
  tags: {
    display: "inline-flex",
    flexWrap: "wrap",
  },
  title: {
    display: "inline-flex",
    textAlign: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  chipItem: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    "&:first-of-type": {
      marginLeft: 0,
    },
  },
}));

interface OwnProps {}

export const SelectedFilters: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const { service, state } = useFilters();

  const handleDelete = () => {
    service.clearFilters();
    service.updateLocation();
  };

  return (
    <div className={classes.root}>
      <div className={classes.tags}>
        {state.search && (
          <span className={classes.chipItem} key={"search"}>
            <Chip
              variant={"outlined"}
              onDelete={() => {
                handleDelete();
              }}
              deleteIcon={<CloseIcon style={{ color: "#6E1F55", fontWeight: "bold" }} />}
              label={"Search: " + state.search}
            />
          </span>
        )}
        {state.categories.map((cat) => {
          return (
            <span className={classes.chipItem} key={cat.id}>
              <Chip
                variant={"outlined"}
                onDelete={() => {
                  handleDelete();
                }}
                deleteIcon={<CloseIcon style={{ color: "#6E1F55", fontWeight: "bold" }} />}
                label={cat.label}
              />
            </span>
          );
        })}
      </div>
    </div>
  );
};
