import { createMuiTheme } from "@material-ui/core/styles";
import { BreakPoints } from "./config/breakpoints";
import getTypography from "./config/typography";
import getOverrides from "./config/overrides";
import { palette } from "./config/palette";

const theme = createMuiTheme({
  spacing: 12,
  palette: palette,
  breakpoints: BreakPoints
});

getTypography(theme);
getOverrides(theme);

export default theme;
