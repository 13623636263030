import gql from "graphql-tag";

export const refreshToken = gql`
  mutation RefreshAuthToken($input: RefreshJwtAuthTokenInput!) {
    refreshJwtAuthToken(input: $input) {
      authToken
    }
  }
`;

export const loginUser = gql`
  mutation loginUser($input: LoginInput!) {
    login(input: $input) {
      authToken
      refreshToken
      user {
        id
        name
        databaseId
        jwtAuthExpiration
      }
    }
  }
`;

export const createUser = gql`
  mutation CreateUser($input: RegisterUserInput!) {
    __typename
    registerUser(input: $input) {
      user {
        firstName
        lastName
        email
      }
    }
  }
`;

export const resetPassword = gql`
  mutation MyMutation($key: String, $login: String, $password: String) {
    __typename
    resetUserPassword(input: { key: $key, login: $login, password: $password }) {
      user {
        email
      }
    }
  }
`;

export const requestNewPassword = gql`
  mutation MyMutation($username: String!) {
    __typename
    sendPasswordResetEmail(input: { username: $username }) {
      user {
        email
      }
    }
  }
`;

export const Viewer = gql`
  query getViewer {
    viewer {
      id
      username
      email
      databaseId
      avatar {
        url
      }
    }
  }
`;
