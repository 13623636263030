import { gql } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { client } from '../../../../apollo/apolloClient';
import { Loading } from '../../loading';

export const RequestFileForm: React.FC<{ media: any }> = ({ media }) => {
  const [error, setError] = useState<Error | undefined>(undefined);
  const [submitted, setSubmitted] = useState(false);

  const sumbitForm = useCallback(async () => {
    try {
      await client.mutate({
        mutation: gql`
          mutation MyMutation {
            __typename
            requestMedia(input: { mediaObjectId: "240" }) {
              success
            }
          }
        `,
      });

      setSubmitted(true);
    } catch (err) {
      setError(err);
      setSubmitted(false);
    }
  }, []);

  useEffect(() => {
    sumbitForm();
  }, [sumbitForm]);

  if (error) {
    return (
      <div id="dialog-request-file-error">
        <Typography paragraph>
          Er is iets mis gegaan. Probeer het op een ander moment opnieuw
        </Typography>
      </div>
    );
  }

  if (submitted) {
    return (
      <div id="dialog-request-file-completed">
        <Typography paragraph>Bedankt voor je aanvraag.</Typography>
        <Typography paragraph>
          We gaan er zo snel mogelijk mee aan de slag en nemen per e-mail contact met je op.{' '}
        </Typography>
        <Typography>Team Enschede Promotie</Typography>
      </div>
    );
  }

  return <Loading contained></Loading>;
};
