import { makeStyles } from "@material-ui/core";
import React from "react";
import { get } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    width: "100%",
  },
}));

interface OwnProps {
  data: any;
}

export const Audio: React.FC<OwnProps> = ({ data }) => {
  const classes = useStyles();
  const src = get(data, "previewDetails.soundcloudEmbedUri", "");
  const thumb = get(data, "featuredImage.node.sourceUrl", "");
  const finalThumb = get(data, "previewDetails.previewImage.sourceUrl", thumb);

  return src ? (
    <>
      <iframe
        title="audio-embed"
        width="100%"
        height="300"
        scrolling="no"
        allow="autoplay"
        src={src}
      ></iframe>
    </>
  ) : (
    <img className={classes.root} src={finalThumb} alt={data.title} />
  );
};
