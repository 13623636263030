import * as React from "react";

import Login from "./login";
import Register from "./register";
import Registered from "./registered";
import { RequestNewPassword } from "./request-new-password";
import ResetPassword from "./reset-password";

interface OwnProps {}

export const Auth: React.FC<OwnProps> = () => {
  return (
    <>
      <Register />
      <ResetPassword />
      <RequestNewPassword />
      <Registered />
      <Login />
    </>
  );
};
