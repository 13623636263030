import { Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    fontSize: "0.9rem",
    lineHeight: "1.25rem",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
}));

interface OwnProps {
  active: boolean;
}

export const CommercialDisclaimer: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return props.active ? (
    <div className={classes.root}>
      <Typography color={"primary"} paragraph>
        <strong>Disclaimer: Dit item is NIET COMMERCIEEL te gebruiken! </strong>
      </Typography>
      <Typography>
        <Link className={classes.link} target="_blank" href="https://enschedepromotie.nl/contact/">
          Neem contact op
        </Link>{" "}
        met Enschede Promotie indien je deze media wenst te gebruiken voor commerciële doeleinden.
      </Typography>
    </div>
  ) : (
    <></>
  );
};
