import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useAuth } from '../../../../hooks/use-auth';
import { AuthState } from '../../../services/auth';
import { UnauthorizedComponent } from '../../unauthorized';

const useStyles = makeStyles((theme) => ({
  button: { marginBottom: theme.spacing(1) },
}));

interface OwnProps {}

export const UnauthorizedButtons: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const auth = useAuth();

  const openLogin = React.useCallback(() => {
    auth.setState(AuthState.SignIn);
  }, [auth]);

  return (
    <>
      <Button
        id="btn-login-mediaobject"
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={openLogin}
      >
        Inloggen
      </Button>
      <Button
        id="btn-download-file-disabled"
        variant="contained"
        disabled
        color="primary"
        className={classes.button}
      >
        Downloaden
      </Button>
      <UnauthorizedComponent />
    </>
  );
};
