import React from 'react';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { faMicrophoneAlt } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 32,
    height: 32,
    background: theme.palette.secondary.main,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.1rem',
  },
}));

interface OwnProps {
  data: any;
}

export const ItemIcon: React.FC<OwnProps> = ({ data }) => {
  const classes = useStyles();

  let icon = faImage;
  switch (get(data, 'details.mediaType')) {
    case 'video':
      icon = faPlayCircle;
      break;
    case 'audio':
      icon = faMicrophoneAlt;
      break;
  }

  return (
    <div className={classes.root}>
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};
