import { Theme } from "@material-ui/core";
import { Overrides } from "@material-ui/core/styles/overrides";

function getOverrides(theme: Theme) {
  const overrides: Overrides = {
    MuiBackdrop: {
      root: {
        backdropFilter: "blur(8px)"
      }
    },

    MuiButton: {
      root: {
        transition: "all 0.5s",
        backgroundColor: "none",
        letterSpacing: "-0.02em",
        fontWeight: 400,
        fontSize: "1.3em",
        fontFamily: "'TradeGothicLT-BoldCondTwenty', sans-serif",
        textTransform: "uppercase",
        color: theme.palette.secondary.main,
        paddingLeft: 36,
        paddingRight: 36,
        "&:hover": {
          color: theme.palette.primary.main,
          backgroundColor: "none",
          "&:hover": {
            backgroundColor: "none"
          }
        }
      },
      contained: {
        borderRadius: 0,
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none"
        }
      },
      containedPrimary: {
        "&:hover": {
          color: "#fff",
          backgroundColor: theme.palette.secondary.main
        }
      },
      containedSecondary: {
        "&:hover": {
          color: "#fff",
          backgroundColor: theme.palette.primary.main
        }
      }
    },

    MuiInputBase: {
      input: {}
    },
    MuiSvgIcon: {
      root: {
        transition: "all 0.5s",
        color: theme.palette.secondary.main,
        "&:hover": {
          color: theme.palette.primary.main
        }
      }
    },
    MuiBadge: {
      badge: {
        color: theme.palette.primary.main,
        lineHeight: 3,
        padding: 2,
        fontSize: ".7em",
        [theme.breakpoints.up("md")]: {
          fontSize: "0.65em",
          minWidth: "17px",
          width: "17px",
          height: "17px"
        }
      }
    },
    MuiChip: {
      root: {
        height: "30px",
        fontSize: "0.8em"
      },
      deleteIcon: {
        marginLeft: "-10px",
        color: theme.palette.secondary.main,
        height: "16px",
        "&:hover": {
          color: theme.palette.primary.main
        }
      }
    }
  };

  for (const [key, value] of Object.entries(overrides)) {
    if (theme && theme.overrides) theme.overrides[key] = value;
  }
}

export default getOverrides;
