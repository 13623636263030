import * as React from "react";
import { useHistory } from "react-router";
import { FilterService, FilterState } from "../services/filter";

const service = new FilterService();
export const FilterContext = React.createContext({ service, state: service.state });

export const FilterProvider: React.FC = (props) => {
  const [state, setState] = React.useState<FilterState>(service.state);

  service.setup(setState, useHistory());

  return <FilterContext.Provider value={{ service, state: { ...state } }}>{props.children}</FilterContext.Provider>;
};
