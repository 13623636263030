import React from "react";
import { makeStyles } from "@material-ui/core";
import { ItemTitle } from "./item-title";
import { ItemOverlay } from "./item-overlay";
import { ItemButton } from "./item-button";
import { ItemThumbnail } from "./item-thumbnail";
import { ItemIcon } from "./item-icon";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    overflow: "hidden",
    position: "relative",
    padding: 0,
    margin: 0,
    [theme.breakpoints.up("md")]: {
      maxHeight: 200,
    },
  },
}));

interface OwnProps {
  data: any;
}

export const GalleryItem: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const data = props.data;
  const [show, setShow] = React.useState(false);
  const history = useHistory();

  return (
    <div
      onClick={() => history.push(data.uri)}
      className={classes.wrapper}
      onMouseOver={() => {
        setShow(true);
      }}
      onMouseOut={() => {
        setShow(false);
      }}
    >
      <ItemOverlay show={show} />
      <ItemTitle data={data} show={show} />
      <ItemThumbnail data={data} />
      <ItemIcon data={data} />
      <ItemButton data={data} show={show} />
    </div>
  );
};
