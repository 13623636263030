import gql from "graphql-tag";

export const MediaObjects = gql`
  query MediaObjects($where: RootQueryToMediaobjectConnectionWhereArgs, $first: Int, $after: String) {
    mediaobjects(where: $where, first: $first, after: $after) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        total
      }

      nodes {
        id
        title
        content
        uri
        modified
        details {
          mediaType
        }
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
      }
    }
  }
`;

export const Categories = gql`
  query Categories($where: RootQueryToCategoryConnectionWhereArgs) {
    categories(where: $where) {
      edges {
        node {
          databaseId
          name
          slug
        }
      }
    }
  }
`;
