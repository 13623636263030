import React from 'react';
import { makeStyles, Link, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    lineHeight: '2em',
  },
  link: {
    color: theme.palette.secondary.contrastText,
    fontSize: '1em',
    fontWeight: 300,
  },
}));

interface OwnProps {}

export const NavMiddelen: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <nav className={classes.nav}>
      <Typography gutterBottom variant={'h5'}>
        Middelen
      </Typography>

      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/outdoor-media/'}
        target={'_blank'}
      >
        Outdoor media
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/online-media/'}
        target={'_blank'}
      >
        Online media
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/folders-brochures/'}
        target={'_blank'}
      >
        Folders & Brochures
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/adverteren/'}
        target={'_blank'}
      >
        Adverteren in Enschede
      </Link>
    </nav>
  );
};
