import React, { useState } from 'react';
import { makeStyles, Button, TextField } from '@material-ui/core';

import { Loading } from '../../loading';
import { useAuth } from '../../../../hooks/use-auth';
import { AuthState } from '../../../services/auth';

const useStyles = makeStyles((theme) => ({
  action: {
    padding: 0,
  },
  loginButton: {
    marginTop: theme.spacing(1),
    marginRight: 0,
    alignSelf: 'flex-end',
  },
  message: {
    marginTop: theme.spacing(1),
    padding: '5px 10px',
    backgroundColor: 'red',
    color: '#fff',
  },
  formControl: {
    width: '100%',
    border: '1px solid #ccc',
    padding: '4px',
  },
  textField: {
    margin: theme.spacing(0.5, 0),
  },
}));

interface OwnProps {}

export const RegistrationForm: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  // const [organisation, setOrganisation] = useState("");
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  const submitForm = React.useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      auth
        .register(name, email)
        .then((data) => {
          setLoading(false);
          auth.setState(AuthState.RegisterSuccess);
        })
        .catch((e) => {
          setLoading(false);
          setMessage(e.message);
        });
    },
    [email, name, auth]
  );

  if (loading) {
    return <Loading contained={true} />;
  }

  console.log(message);

  return (
    <form onSubmit={submitForm}>
      {loading ? <Loading contained={true} /> : ''}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Uw emailadres"
        label="E-mail"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        name="email"
        className={classes.textField}
      />
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Naam"
        label="Naam"
        value={name}
        onChange={(e) => setName(e.target.value)}
        type="text"
        name="name"
        className={classes.textField}
      />
      {/* <TextField
        fullWidth
        variant="outlined"
        label="Organisatie"
        placeholder="Organisatie"
        value={organisation}
        onChange={(e) => setOrganisation(e.target.value)}
        type="text"
        name="organisation"
        className={classes.textField}
      /> */}
      <Button
        id="btn-register-submit"
        type="submit"
        className={classes.loginButton}
        variant="contained"
        color="primary"
        onClick={submitForm}
        disabled={!email || !name}
      >
        Registreren
      </Button>
      {message ? (
        <div className={classes.message}>
          {message.replace('&lt;strong&gt;Fout&lt;/strong&gt;:', '')}
        </div>
      ) : (
        ''
      )}
    </form>
  );
};
