import React from "react";
import { CssBaseline, Grid, makeStyles, ThemeProvider } from "@material-ui/core";
import theme from "../../theme/theme";
import Footer from "./footer/footer";
import Header from "./header/header";
// import GalleryFilters from "../components/gallery-filtering/gallery-filters"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    minHeight: "100vh",
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(15),
    },
  },
  container: {
    display: "flex",
    flexGrow: 1,
    maxWidth: "unset",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  leftCol: {
    color: theme.palette.text.primary,
    margin: 0,
    padding: 0,
  },
  rightCol: {
    color: theme.palette.text.primary,
    margin: 0,
    padding: 0,
  },
  mediaGrid: {
    padding: 0,
    margin: 0,
  },
}));

interface OwnProps {}

export const Layout: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <Header />
        <div className={classes.container}>
          <Grid container className={classes.mediaGrid}>
            {/* <Grid className={classes.leftCol} item sm={4} md={3} lg={2}>
              <GalleryFilters />
            </Grid> */}
            <Grid className={classes.rightCol} item sm={12} md={12} lg={12}>
              <div>{props.children}</div>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
};
