import React from "react";
import placeholder from "../../../../images/placeholder.jpg";
import { ImagePreview } from "../image-preview";

import { get } from "lodash";

interface OwnProps {
  data: any;
}

export const Image: React.FC<OwnProps> = ({ data }) => {
  const thumb = get(data, "featuredImage.node.sourceUrl", "");
  const finalThumb = get(data, "previewDetails.previewImage.sourceUrl", thumb);

  return <ImagePreview src={finalThumb || placeholder} alt={data.title} />;
};
