import React from "react";
import { Switch, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { PageOverview } from "./pages/page-overview";
import { PageMediaObject } from "./pages/page-media-object";
import { Auth } from "./components/auth/auth";
import { FilterProvider } from "./providers/filter-provider";
import { Layout } from "./layout/layout";
// import { PageCompleteRegistration } from "./pages/page-complete-registration";

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <FilterProvider>
          <Layout>
            <Switch>
              <Route exact path="/auth/complete-registration" component={PageOverview} />
              <Route exact path="/mediaobject/:uri" component={PageMediaObject} />
              <Route exact path="/categorie/:category" component={PageOverview} />
              <Route exact path="/zoeken/:search" component={PageOverview} />
              <Route exact path="/" component={PageOverview} />
            </Switch>
            <Auth />
          </Layout>
        </FilterProvider>
      </Switch>
    </BrowserRouter>
  );
};
