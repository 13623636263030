import React from "react";
import { makeStyles, AppBar, Toolbar, Hidden } from "@material-ui/core";

import { MobileMenu } from "../../../components/mobile/mobile-menu";
import { TopNav } from "./top-nav";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: -theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    paddingLeft: 0,
    flexDirection: "row",
  },
  toolBar: {
    padding: 0,
  },
}));

interface OwnProps {
  counter?: number;
}

export const NavMain: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <nav style={{ marginRight: -8 }}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Hidden mdUp>
              <MobileMenu />
            </Hidden>
            <Hidden smDown>
              <TopNav />
            </Hidden>
          </Toolbar>
        </AppBar>
      </nav>
    </React.Fragment>
  );
};
