import { red } from "@material-ui/core/colors";

export const palette = {
  primary: {
    main: "#ee272c"
  },
  secondary: {
    main: "#791C55",
    contrastText: "#ffffff"
  },
  error: {
    main: red.A400
  },
  background: {
    default: "#ffffff"
  },
  text: {
    primary: "#333"
  }
};
