import { makeStyles, Container, Grid } from '@material-ui/core';
import { Legal } from './partials/legal';
import { NavEnschedePromotie } from './partials/nav-enschede-promotie';
import { NavProjecten } from './partials/nav-projecten';
import { NavEvenementen } from './partials/nav-evenementen';
import { NavMiddelen } from './partials/nav-middelen';
import { NavContact } from './partials/nav-contact';
import ruit from '../../../images/ruit.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    position: 'relative',
    '& *': {
      color: theme.palette.secondary.contrastText,
    },
  },
  element: {
    backgroundImage: 'url(' + ruit + ')',
    backgroundSize: '180%',
    backgroundPosition: '20px center',
    backgroundRepeat: 'no-repeat',
    width: '150px',
    height: '100%',
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  container: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1660,
  },
}));

interface ownProps {
  title?: string;
}

function Footer(props: ownProps) {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <div className={classes.element}></div>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={4} lg={'auto'}>
            <NavEnschedePromotie />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={'auto'}>
            <NavProjecten />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={'auto'}>
            <NavEvenementen />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={'auto'}>
            <NavMiddelen />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={'auto'}>
            <NavContact />
          </Grid>
        </Grid>
        <Legal></Legal>
      </Container>
    </footer>
  );
}

export default Footer;
