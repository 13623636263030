import { makeStyles } from "@material-ui/core";
import React from "react";
import * as Query from "../../../../../gql/categories";
import { Loading } from "../../../../components/loading";
import { useQuery } from "@apollo/client";
import { flatListToHierarchical } from "../../../../helpers/children-categories";
import { CategoryNavItem } from "./filter/category-nav-item";

interface ownProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginRight: -8,
  },
}));

function Categories(props: ownProps) {
  const classes = useStyles();

  const { loading, data } = useQuery(Query.Categories, {
    fetchPolicy: "network-only",
  });

  if (loading) return <Loading />;

  if (data) {
    // let hierarchicalList = flatListToHierarchical(data.menu.menuItems.nodes);

    const categories = flatListToHierarchical(data.menu.menuItems.nodes);

    return (
      <div className={classes.root}>
        {categories.map((item: any, index: number) => {
          return <CategoryNavItem item={item} key={index} />;
        })}
      </div>
    );
  }

  return <></>;
}

export default Categories;
