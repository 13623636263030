import * as React from "react";
import { AuthContext } from "../../services/auth";

interface OwnProps {
  unauthorizedComponent?: React.FC<any>;
}

export const RequireAuth: React.FC<OwnProps> = (props) => {
  const { user } = React.useContext(AuthContext);

  if (user && props.children) {
    return <>{props.children}</>;
  }

  if (props.unauthorizedComponent) {
    return <props.unauthorizedComponent />;
  }

  return null;
};
