import { useQuery } from "@apollo/client";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import * as Query from "../../gql/relatedmediaobjects";
import { FilterPaginate } from "./gallery-filtering/pagination/filter-paginate";
import { FilterResults } from "./gallery-filtering/partials/filter-results";
import { GalleryItem } from "./gallery/gallery-item";
import { Loading } from "./loading";
// import Loading from "./loading";
import { get } from "lodash";

interface OwnProps {
  media: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
}));

export const RelatedMedia: React.FC<OwnProps> = (props) => {
  const perPage = 8;
  const [page, setPage] = React.useState(1);
  let categories = (props.media && props.media.categories && props.media.categories.nodes) || [];

  categories = categories.map((item: any) => {
    return item.databaseId;
  });

  // const [items, setItems] = useState([]);
  const { loading, data } = useQuery(Query.MediaObjects, {
    fetchPolicy: "network-only",
    variables: { categories: categories, notIn: [props.media.databaseId] },
  });

  const classes = useStyles();
  if (loading) return <Loading />;

  if (data) {
    const items = get(data, "mediaobjects.nodes", []);

    return (
      <>
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={12}>
            <Typography variant={"h3"}>Gerelateerde media</Typography>
          </Grid>
          <Grid item xs={12}>
            <FilterResults value={items.length} />
            {" - "}
            <FilterPaginate
              onChange={(page: any) => {
                setPage(page);
              }}
              active={items.length > perPage}
              perPage={perPage}
              data={data}
            />
          </Grid>
          {items.slice((page - 1) * perPage, page * perPage).map((data: any, i: number) => (
            <Grid key={i} item xs={"auto"}>
              <GalleryItem data={data} />
            </Grid>
          ))}
        </Grid>
      </>
    );
  } else {
    return <>loading</>;
  }
};
