const imageTypes = ["image"] as const;
const videoTypes = ["video"] as const;
const audioTypes = ["audio"] as const;

export enum MediaType {
  Video = "video",
  Image = "image",
  Audio = "audio"
}

type Extension = typeof imageTypes | typeof videoTypes | typeof audioTypes;

export const getMediaType = (extension: Extension[number]) => {
  if (imageTypes.includes(extension as any)) return MediaType.Image;
  if (videoTypes.includes(extension as any)) return MediaType.Video;
  if (audioTypes.includes(extension as any)) return MediaType.Audio;

  return undefined;
};
