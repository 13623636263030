import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
  },
  canvas: {
    maxWidth: "100%",
    width: "100%",
    margin: 0,
    padding: 0,
  },
}));

interface OwnProps {
  src: string;
  alt?: string;
}

export const ImagePreview: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [context, setContext] = React.useState<CanvasRenderingContext2D | null>(null);
  const [size, setSize] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    if (canvasRef.current) {
      const renderCtx = canvasRef.current.getContext("2d");

      if (renderCtx) {
        setContext(renderCtx);
      }

      if (context) {
        // put the image in the canvas
        const base_image = new Image();
        base_image.src = props.src;
        base_image.onload = function() {
          setSize({ width: base_image.width, height: base_image.height });
          if (canvasRef.current) {
            context.drawImage(base_image, 0, 0, canvasRef.current.width, canvasRef.current.height);

            // add watermerk
            const pattern_image = new Image();
            pattern_image.src = process.env.PUBLIC_URL + "/images/pattern.png";
            pattern_image.onload = function() {
              const pattern = context.createPattern(pattern_image, "repeat");
              if (pattern !== null) {
                context.rect(0, 0, base_image.width, base_image.height);
                context.fillStyle = pattern;
                context.fill();
              }
            };
          }
        };
      }
    }
  }, [context, props.src]);
  return (
    <div className={classes.root}>
      <canvas
        id="canvas"
        ref={canvasRef}
        className={classes.canvas}
        width={size.width}
        height={size.height}
      ></canvas>
    </div>
  );
};
