import * as React from "react";
import { Link, makeStyles } from "@material-ui/core";
import { useAuth } from "../../hooks/use-auth";
import { AuthState } from "../services/auth";

const useStyles = makeStyles((theme) => ({
  root: {},
  message: { fontSize: "0.9em" },
  link: { color: theme.palette.primary.main, textDecoration: "none" },
}));

interface OwnProps{}

export const UnauthorizedComponent: React.FC<OwnProps> = () => {
  const classes = useStyles();
  const auth = useAuth();

  const openLogin = React.useCallback(() => {
    auth.setState(AuthState.SignIn);
  }, [auth]);

  return (
    <div className={classes.root}>
     <span className={classes.message}>
        <Link className={classes.link} onClick={openLogin}>
          Inloggen&nbsp; 
        </Link>
         is vereist om deze afbeelding te downloaden
      </span>
    </div>
  );
};
