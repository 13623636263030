import gql from "graphql-tag";

export const MediaObject = gql`
  query mediaobjectBy($uri: String!) {
    mediaobjectBy(uri: $uri) {
      id
      databaseId
      title
      content
      mediaInfo {
        key
        name
        value
      }
      featuredImage {
        node {
          sourceUrl(size: MEDIUM_LARGE)
        }
      }
      details {
        mediaType
        noCommercialUsage
        onRequest
        file {
          databaseId
          mediaItemUrl
          sourceUrl
          fileSize
          mediaDetails {
            height
            width
          }
        }
      }
      previewDetails {
        youtubeEmbedUri
        soundcloudEmbedUri
        previewType
        previewImage {
          sourceUrl(size: MEDIUM_LARGE)
          mediaItemUrl
          mediaDetails {
            sizes {
              name
              width
              height
              sourceUrl
            }
          }
        }
      }
      categories {
        nodes {
          id
          databaseId
          name
          slug
        }
      }
      mediaTags {
        nodes {
          id
          databaseId
          name
          slug
        }
      }
    }
  }
`;
