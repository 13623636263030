import * as React from "react";
import { generatePath } from "react-router";
import * as H from "history";

import { client } from "../../apollo/apolloClient";
import { Categories } from "../../gql/allmediaobjects";

interface KeyVal {
  key: string;
  value: string;
}

export interface FilterState {
  search: string;
  categories: FilterCategory[];
  tags: KeyVal[];
  details: KeyVal[];
}

interface FilterCategory {
  id: string;
  slug: string;
  label: string;
}

const defaultState = {
  search: "",
  categories: [],
  tags: [],
  details: []
};

export class FilterService {
  public state: FilterState = defaultState;
  private _history: H.History<any> | undefined = undefined;

  protected setState: React.Dispatch<FilterState> = () => {};

  public setup = async (setState: any, history: H.History<any>) => {
    this._history = history;
    this.setState = setState;
  };

  public initParams = async (params: { search?: string; category?: string }) => {
    this.clearFilters();
    if (params.category) {
      this.state.categories = await resolveCategories([params.category]);
    }

    this.state.search = params.search || "";
    this.setState(this.state);
  };

  public clearFilters = () => {
    this.state = {
      search: "",
      categories: [],
      tags: [],
      details: []
    };
  };

  public search = (query: string) => {
    this.state.categories = [];
    this.state.search = query;
    this.updateLocation();
  };

  public setCategory = (cat: FilterCategory) => {
    this.state.search = "";
    this.state.categories = [cat];
    this.updateLocation();
  };

  public removeCategory = (cat: FilterCategory) => {
    const index = this.state.categories.indexOf(cat);

    if (index > -1) {
      this.state.categories.splice(index, 1);
      this.setState({
        ...this.state,
        categories: [...this.state.categories, cat]
      });
    }
  };

  public removeCategories = () => {
    this.state.categories = [];
  };

  public setCategories = (categories: FilterCategory[]) => {
    this.state.categories = [
      ...this.state.categories,
      ...categories.filter(cat => {
        return this.state.categories.indexOf(cat) < 0;
      })
    ];
  };

  public updateLocation = () => {
    if (!this._history) {
      return;
    }

    let newLocation = "/";

    if (this.state.search) {
      newLocation = generatePath("/zoeken/:search", { search: this.state.search || "" });
    }

    if (this.state.categories.length > 0) {
      if (this.state.categories.length === 1) {
        newLocation = generatePath("/categorie/:category", {
          category: this.state.categories[0].slug || ""
        });
      }

      if (this.state.categories.length > 1) {
        newLocation = generatePath("/categorieen/:categories", {
          categories: this.state.categories.map(cat => cat.slug).join(",")
        });
      }
    }

    if (newLocation) {
      this._history.push(newLocation);
    }
  };
}

const resolveCategories = async (slugs: string[]) => {
  const result = await client.query({
    query: Categories,
    variables: {
      where: { slug: slugs }
    }
  });

  return result.data.categories.edges.map((edge: any) => ({
    id: edge.node.databaseId,
    slug: edge.node.slug,
    label: edge.node.name
  }));
};
