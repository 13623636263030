import React, { useEffect } from "react";
import { makeStyles, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useFilters } from "../../../../../hooks/use-filters";
import { useParams } from "react-router";

interface ownProps {
  // title?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 4,
  },
  icon: {},
  search: {
    marginLeft: theme.spacing(1),
    fontSize: "1em",
    fontWeight: 700,
    textTransform: "uppercase",
    color: theme.palette.primary.main,
  },
  searchField: {
    textTransform: "uppercase",
  },
}));

function Search(props: ownProps) {
  const classes = useStyles();
  const { service, state } = useFilters();
  const params = useParams<{ search: string }>();
  const [query, setQuery] = React.useState(params.search || "");
  const [timeout, setSearchTimeout] = React.useState<any>(undefined);

  useEffect(() => {
    setQuery(state.search || "");
  }, [state]);

  return (
    <div className={classes.root}>
      <SearchIcon className={classes.icon} />
      <div className={classes.search}>
        <InputBase
          placeholder="MEDIA ZOEKEN.."
          classes={{ input: classes.searchField }}
          inputProps={{ "aria-label": "search" }}
          value={query}
          onChange={(e: any) => {
            clearTimeout(timeout);
            const value = e.target.value;
            setQuery(value);
            if (!value) {
              service.clearFilters();
              service.updateLocation();
            } else {
              setSearchTimeout(
                setTimeout(() => {
                  service.search(value);
                }, 300)
              );
            }
          }}
        />
      </div>
    </div>
  );
}

export default Search;
