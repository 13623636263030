import * as React from "react";
import { useAuth } from "../../hooks/use-auth";
import { AuthContext, AuthState } from "../services/auth";

interface OwnProps {}

export const AuthProvider: React.FC<OwnProps> = (props) => {
  const auth = useAuth();
  const [state, setState] = React.useState<any>({
    user: undefined,
    state: AuthState.SignedOut,
    error: undefined,
  });

  React.useEffect(() => {
    auth.onStateChange((newState: any) => {
      setState(newState);
    });
  }, [auth]);

  return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>;
};
