import { Button } from '@material-ui/core';
import * as React from 'react';
import { useAuth } from '../../../hooks/use-auth';
// import {UserInfo} from "../../layout/header/user-info";
import { RequireAuth } from './require-auth';
import { AuthState } from '../../services/auth';

export const LoginButton: React.FC = (props) => {
  const auth = useAuth();

  return (
    <RequireAuth
      unauthorizedComponent={() => {
        return (
          <Button
            id="btn-login-header"
            variant={'text'}
            color={'secondary'}
            onClick={() => {
              auth.setState(AuthState.SignIn);
            }}
          >
            Inloggen
          </Button>
        );
      }}
    >
      <Button
        id="btn-logout-header"
        onClick={() => {
          auth.signOut();
        }}
        // className={classes.button}
        variant={'text'}
        color={'secondary'}
      >
        Uitloggen
      </Button>
    </RequireAuth>
  );
};
