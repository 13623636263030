import { makeStyles, IconButton, Typography } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },

  close: {
    fontSize: "0.9em",
    marginTop: "-1em",
  },
}));

interface OwnProps {
  handleClose?: any;
  title?: any;
}

export const PopupHeader: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const handleClose = props.handleClose;
  const title = props.title || "Title";

  return (
    <>
      <div className={classes.header}>
        <Typography paragraph variant="h3">
          {title}
        </Typography>
        <div>
          <IconButton onClick={handleClose} style={{ marginTop: -10, marginRight: -12 }}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};
