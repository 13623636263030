import { makeStyles } from "@material-ui/core";
import React from "react";
import { get } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    width: "100%",
  },
}));

interface OwnProps {
  data: any;
}

export const Video: React.FC<OwnProps> = ({ data }) => {
  const classes = useStyles();
  const src = get(data, "previewDetails.youtubeEmbedUri", "");
  const thumb = get(data, "featuredImage.node.sourceUrl", "");
  const finalThumb = get(data, "previewDetails.previewImage.sourceUrl", thumb);

  return src ? (
    <iframe
      title="youtube-embed"
      width="100%"
      height="450"
      src={src + "?autoplay=true"}
      style={{ border: 0 }}
      allow="accelerometer; autoplay; muted; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    ></iframe>
  ) : (
    <img className={classes.root} src={finalThumb} alt={data.title} />
  );
};
