import gql from "graphql-tag";

export const MediaObjects = gql`
  query relatedMediaObjects($categories: [ID]!, $notIn: [ID]!) {
    mediaobjects(first: 100, where: { categoryIn: $categories, notIn: $notIn }) {
      nodes {
        id
        title
        uri
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        author {
          node {
            username
            email
          }
        }
        details {
          file {
            sourceUrl
          }
        }
        previewDetails {
          previewImage {
            sourceUrl
            srcSet
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`;
