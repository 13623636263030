import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: 500,
    fontSize: "1em",
    color: theme.palette.secondary.main,
    fontFamily: "'TradeGothicLT-BoldCondTwenty', sans-serif",
  },
}));

interface OwnProps {
  value?: number;
}

export const FilterResults: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <Typography variant={"button"} className={classes.text}>
      {props.value} {props.value === 1 ? "resultaat" : "resultaten"}
    </Typography>
  );
};
