import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  overlay: {
    transition: "all 1s",
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    background:
      "linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(0,0,0,0.5) 100%)",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    zIndex: 2,
    pointerEvents: "none",
  },
  overlayInview: { opacity: 1 },
}));

interface OwnProps {
  show?: boolean;
}

export const ItemOverlay: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const show = props.show;

  return (
    <div
      className={clsx({
        [classes.overlay]: true,
        [classes.overlayInview]: show,
      })}
    ></div>
  );
}