import { Button, Dialog, DialogContent, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { AuthState } from '../../services/auth';
import { LoginForm } from './forms/login-form';
import { PopupHeader } from '../popup-header';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    borderTop: '1px solid #ccc',
  },
}));

interface OwnProps {}

export const Login: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const close = React.useCallback(() => {
    auth.setState(AuthState.SignedOut);
  }, [auth]);

  return (
    <Dialog
      id="dialog-login"
      PaperProps={{
        style: {
          width: 380,
          borderRadius: 0,
        },
      }}
      open={auth.state === AuthState.SignIn}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <PopupHeader title={'Inloggen'} handleClose={close} />
        <LoginForm />
      </DialogContent>
      <div className={classes.footer}>
        <Typography paragraph>Heb je nog geen account?</Typography>

        <Button
          onClick={(e) => {
            auth.setState(AuthState.Register);
          }}
          variant="contained"
          color="secondary"
        >
          Registreren
        </Button>
      </div>
    </Dialog>
  );
};

export default Login;
