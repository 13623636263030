import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import placeholder from "../../../images/placeholder.jpg";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: "auto",
    cursor: "pointer",
    height: "24vw",
    zIndex: 3,
    [theme.breakpoints.up("md")]: {
      maxHeight: 230,
      width: "auto",
      height: "100%",
    },
  },
}));

interface OwnProps {
  data: any;
  show?: boolean;
}

export const ItemThumbnail: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const data = props.data;
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const featured = data.featuredImage;
  let featuredURl = (featured && featured.node && featured.node.sourceUrl) || placeholder;

  return (
    <Link to={data.uri}>
      {!imageLoaded && (
        <Skeleton
          variant="rect"
          width={window.innerWidth / 5}
          height={230}
          className={classes.thumbnail}
        />
      )}
      <img
        className={classes.thumbnail}
        src={featuredURl}
        alt={data.title}
        onLoad={() => setImageLoaded(true)}
      />
    </Link>
  );
};
