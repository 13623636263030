import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  title: {
    position: "absolute",
    top: "-100%",
    height: "100%",
    left: 0,
    alignItems: "top",
    justifyContent: "center",
    padding: theme.spacing(2),
    color: "#fff",
    zIndex: 4,
    pointerEvents: "none",
    transition: "all 0.25s",
    textShadow: "5px rgba(0,0,0.0.2)",
    fontSize: "1.1em",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    }
  },
  titleInview: {
    top: 0,
  },
}));

interface OwnProps {
  data: any;
  show?: boolean;
}

export const ItemTitle:React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const data = props.data;
  const show = props.show;

  return (
    <div
      className={clsx({
        [classes.title]: true,
        [classes.titleInview]: show,
      })}
    >
      {data.title}
    </div>
  );
}