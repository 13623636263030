import React from 'react';
import { makeStyles, Link, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    lineHeight: '2em',
  },
  link: {
    color: theme.palette.secondary.contrastText,
    fontSize: '1em',
    fontWeight: 300,
  },
}));

interface OwnProps {}

export const NavEvenementen: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <nav className={classes.nav}>
      <Typography gutterBottom variant={'h5'}>
        Evenementen
      </Typography>

      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/evenementen/evenement-organiseren/'}
        target={'_blank'}
      >
        Evenement organiseren
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/evenementen/evenementenpromotie/'}
        target={'_blank'}
      >
        Evenementenpromotie
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/evenementen/muziek-in-de-straten/'}
        target={'_blank'}
      >
        Muziek in de straten
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/evenementen/winter-wonderland/'}
        target={'_blank'}
      >
        Winter Wonderland
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/evenementen/koningsfestival/'}
        target={'_blank'}
      >
        Koningsfestival
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/evenementen/introductieconcert/'}
        target={'_blank'}
      >
        Introductieconcert
      </Link>
    </nav>
  );
};
