import React from "react";
import { useQuery } from "@apollo/client";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as Query from "../../gql/mediaobject";
import { ActionButtons } from "../components/mediaobjects/partials/action-buttons";
import { Preview } from "../components/mediaobjects/preview";
import { MediaDetails } from "../components/mediaobjects/media-details";
import { CommercialDisclaimer } from "../components/commercial-disclaimer";
import { RelatedMedia } from "../components/related-media";
import { RouteComponentProps } from "react-router";
import { BackButton } from "../components/back-button";

interface PageQueryVars {
  uri: string | string[] | undefined;
}

interface PageRecord {
  mediaobjectBy: {
    id: string;
    title?: string;
    modified: string;
    content: string;
    details: any;
    categories: any;
    mediaTags: any;
    author: any;
    featuredImage: any;
    mediaInfo: any;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    marginTop: theme.spacing(0),
    [theme.breakpoints.down("md")]: {
      position: "relative",
      top: 0,
      marginLeft: -32,
      marginRight: -32,
      padding: 0,
      width: "calc(100% + 56px)",
      margin: 0,
    },
  },
  previewContainer: {
    paddingBottom: theme.spacing(0) + "px !important",
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(3) + "px !important",
    },
  },
}));

interface OwnProps extends RouteComponentProps<{ uri: string }> {}

export const PageMediaObject: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const { data } = useQuery<PageRecord, PageQueryVars>(Query.MediaObject, {
    variables: { uri: props.match.params.uri },
  });

  const media: any = (data && data.mediaobjectBy) || {
    details: {},
    previewDetails: {},
  };

  return (
    <div style={{ maxWidth: 1600, margin: "auto" }}>
      <BackButton />
      <Grid container className={classes.root} spacing={5}>
        <Grid item sm={12} md={12} lg={6} className={classes.previewContainer}>
          <Preview media={media} />
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Typography gutterBottom variant={"h3"}>
            {media.title || "Title not set"}
          </Typography>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6} lg={8}>
              <MediaDetails media={media} />
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <CommercialDisclaimer active={media.details.noCommercialUsage} />
              <ActionButtons media={media} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <RelatedMedia media={media} />
      <br />
      <br />
      <br />
    </div>
  );
};
