import React from "react";
import { makeStyles, Paper, MenuItem, MenuList, Button, Popper, Fade } from "@material-ui/core";
import { useFilters } from "../../../../../../hooks/use-filters";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: theme.spacing(1),
  },
  button: {
    fontSize: "1.1em",
    textAlign: "left",
    position: "relative",
  },
  popOut: {
    left: 0,
    borderRadius: 0,
    boxShadow: "1px 4px 8px rgba(0, 0, 0, 0.3)",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  menuItem: {
    textDecoration: "none",
    textAlign: "left",
    textTransform: "none",
    fontSize: ".9em",
    fontFamily: "TradeGothicLT, sans-serif",
    "&:hover": {
      background: theme.palette.secondary.main,
    },
  },
  link: {
    textDecoration: "none",
  },
}));

interface OwnProps {
  item: any;
}

export const CategoryNavItem: React.FC<OwnProps> = (props) => {
  const filters = useFilters();
  const classes = useStyles();
  const anchorRef = React.useRef<any>(null);
  const arrowRef = React.useRef<any>(null);
  const [open, setOpen] = React.useState(false);

  return (
    <div onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <Button
        aria-describedby={"cat-filter-" + props.item.key}
        id={"cat-filter-" + props.item.key}
        key={"cat-filter-" + props.item.key}
        ref={anchorRef}
        className={classes.button}
        onClick={() => {
          filters.service.clearFilters();
          filters.service.setCategory({
            id: props.item.connectedNode.node.databaseId,
            slug: props.item.connectedNode.node.slug,
            label: props.item.connectedNode.node.name,
          });
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: props.item.label || "Category name" }} />
      </Button>
      <Popper
        id={"cat-filter-" + props.item.key}
        style={{ zIndex: 999 }}
        placement="bottom-start"
        anchorEl={anchorRef.current}
        open={open}
        transition
        disablePortal={true}
      >
        {({ TransitionProps, placement }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper ref={arrowRef} elevation={0} className={classes.popOut}>
              <MenuList>
                {props.item.children.map((child: any) => {
                  return (
                    <div
                      key={child.key}
                      className={classes.link}
                      onClick={() => {
                        filters.service.clearFilters();
                        filters.service.setCategory({
                          id: child.connectedNode.node.databaseId,
                          slug: child.connectedNode.node.slug,
                          label: child.connectedNode.node.name,
                        });
                      }}
                    >
                      <MenuItem className={classes.menuItem}>
                        <div dangerouslySetInnerHTML={{ __html: child.label }} />
                      </MenuItem>
                    </div>
                  );
                })}
              </MenuList>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};
