import React from "react";
import { Collapse, IconButton, List, ListItem, makeStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import * as Query from "../../../gql/categories";
import { useQuery } from "@apollo/client";
import { Loading } from "../loading";
import { flatListToHierarchical } from "../../helpers/children-categories";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { LoginButton } from "../auth/login-button";
import { useFilters } from "../../../hooks/use-filters";

interface OwnProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: 70,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#fff",
    zIndex: 999,
    transition: "all .3s",
    overflow: "auto",
    paddingBottom: 60,
  },
  iconButton: {
    padding: 0,
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      top: -3,
    },
  },
  topLinks: {
    display: "inline-flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    "& button": {
      fontSize: "1em",
    },
  },
  listItem: {
    textAlign: "center",
  },
  link: {
    display: "block",
    // width: "100%",
    color: theme.palette.secondary.main,
    fontWeight: 400,
    textAlign: "center",
    fontSize: "1.2em",
    textTransform: "uppercase",
    fontFamily: "'TradeGothicLT-BoldCondTwenty', sans-serif",
  },
  subitem: {
    color: theme.palette.text.primary,
    textDecoration: "none !important",
    display: "block",
    textAlign: "center",
    padding: "6px 0",
  },
}));

export const MobileMenu: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<string[]>([]);
  const [menuActive, setMenuActive] = React.useState(false);
  const filters = useFilters();

  // fetch categories here.
  const { loading, data } = useQuery(Query.Categories);

  const handleClick = (key: string) => {
    let items = open;
    items.includes(key) ? items.splice(items.indexOf(key), 1) : items.push(key);
    setOpen([...items]);
  };

  if (loading) return <Loading />;

  if (data) {
    const categories = flatListToHierarchical(data.menu.menuItems.nodes);

    return (
      <React.Fragment>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.iconButton}
          onClick={() => {
            setMenuActive(!menuActive);
          }}
        >
          {menuActive ? <CloseIcon /> : <MenuIcon />}
        </IconButton>

        <div
          className={classes.root}
          style={{
            opacity: menuActive ? "100%" : "0%",
            pointerEvents: menuActive ? "auto" : "none",
          }}
        >
          {data && (
            <List component="nav" className={classes.root}>
              <div className={classes.topLinks}>
                <LoginButton />
              </div>

              {categories.map((cat: any) => {
                return (
                  <>
                    <ListItem
                      button
                      onClick={() => handleClick(cat.key)}
                      className={classes.listItem}
                    >
                      <div
                        className={classes.link}
                        style={{
                          width: "100%",
                          color: open.includes(cat.key) ? "#ee272c" : "#791C55",
                        }}
                      >
                        {parse(cat.label)}
                      </div>
                    </ListItem>
                    {cat.children.map((subcat: any) => {
                      return (
                        <Collapse in={open.includes(cat.key)} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <Link
                              key={subcat.key}
                              to={subcat.connectedNode.node.uri}
                              className={classes.subitem}
                              onClick={() => {
                                filters.service.clearFilters();
                                filters.service.setCategory({
                                  id: subcat.connectedNode.node.databaseId,
                                  slug: subcat.connectedNode.node.slug,
                                  label: subcat.connectedNode.node.name,
                                });
                                setMenuActive(false);
                              }}
                            >
                              {parse(subcat.label)}
                            </Link>
                          </List>
                        </Collapse>
                      );
                    })}
                  </>
                );
              })}
            </List>
          )}
        </div>
      </React.Fragment>
    );
  }

  return <></>;
};
