import * as React from "react";
import { client, tokenClient } from "../../apollo/apolloClient";
import * as Query from "../../gql/mutations/iam";
import Cookies from "js-cookie";

export enum AuthState {
  SignIn = "sign-in",
  SignedIn = "signed-in",
  SignedOut = "signed-out",
  Loading = "loading",
  Register = "register",
  RegisterSuccess = "register-success",
  ResetPassword = "reset-password",
  RequestNewPassword = "request-new-password"
}

export class AuthService {
  public currentUser: any;
  public state = AuthState.SignedOut;
  public _error: Error | undefined = undefined;
  public newPass = "";

  public get error(): { key: string; message: string } | undefined {
    let message = (this._error && this._error.message) || "";

    if (this._error) {
      switch (message) {
        case "empty_username":
          message = "Gebruikersnaam is verplicht";
          break;
        case "invalid_username":
          message = "Gebruikersnaam is onjuist";
          break;
        case "invalid_email":
          message = "Gebruikersnaam is onjuist";
          break;
        case "empty_password":
          message = "Wachtwoord is verplicht";
          break;
        case "incorrect_password":
          message = "Wachtwoord is onjuist";
          break;
      }
    }

    return this._error && { key: this._error.message, message };
  }

  private stateCallback = (state: any) => {};

  constructor() {
    const action = new URLSearchParams(window.location.search).get("action");
    if (action === "rp") {
      this.signOut();
      this.setState(AuthState.ResetPassword);
    } else {
      try {
        this.getUser();
      } catch (err) {
        console.error(err);
      }
    }
  }

  public getUser = async (): Promise<{} | undefined> => {
    this.setState(AuthState.Loading);

    try {
      const token = await this.getAccessToken();
      if (token) {
        const userInfo = await client.query({ query: Query.Viewer });
        this.currentUser = userInfo.data.viewer;
        this.setState(AuthState.SignedIn);
        return this.currentUser;
      }
    } catch {
      this.signOut();
    }
    return undefined;
  };

  public signIn = async (username: string, password: string) => {
    this.setError(undefined);
    try {
      const result = await client.mutate({
        mutation: Query.loginUser,
        variables: {
          input: {
            username: username,
            password: password
          }
        }
      });

      this.setAccessToken(result.data.login.authToken, result.data.login.user.jwtAuthExpiration);
      this.setRefreshToken(result.data.login.refreshToken);

      await this.getUser();
    } catch (err) {
      this.setError(err);
    }
  };

  public signOut = async () => {
    this.currentUser = undefined;
    this.removeTokens();
    this.setState(AuthState.SignedOut);
  };

  public register = async (username: string, email: string) => {
    const result = await client
      .mutate({
        mutation: Query.createUser,
        variables: {
          input: {
            username,
            email
          }
        }
      })
      .then(() => {
        this.setState(AuthState.RegisterSuccess);
      });

    return result;
  };

  public setState = (state: AuthState) => {
    this._error = undefined;
    this.state = state;
    this.pushStateChange();
  };

  private setError = (error: Error | undefined) => {
    this._error = error;
    this.pushStateChange();
  };

  private pushStateChange = () => {
    this.stateCallback({
      user: this.currentUser,
      state: this.state,
      error: this.error
    });
  };

  public onStateChange = (callback: any) => {
    this.stateCallback = callback;
  };

  public refreshToken = async () => {
    if (Cookies.get("refreshToken")) {
      const variables = {
        input: {
          jwtRefreshToken: Cookies.get("refreshToken")
        }
      };

      const result = await tokenClient.mutate({
        mutation: Query.refreshToken,
        variables
      });
      if (result.data.refreshJwtAuthToken.authToken) {
        this.setAccessToken(result.data.refreshJwtAuthToken.authToken, (Date.now() / 1000 + 300).toString());
      }
    }
  };

  public resetPassword = async (password: String, key?: string, login?: string) => {
    key = key || new URLSearchParams(window.location.search).get("key") || "";
    login = login || new URLSearchParams(window.location.search).get("login") || "";

    try {
      await client.mutate({
        mutation: Query.resetPassword,
        variables: {
          clientMutationId: "ResetPassword",
          password,
          key,
          login
        }
      });

      this.setState(AuthState.SignIn);
    } catch (err) {
      this.setError(err);
    }

    return;
  };

  public requestNewPassword = async (username: string) => {
    try {
      await client.mutate({
        mutation: Query.requestNewPassword,
        variables: {
          clientMutationId: "RequestNewPassword",
          username
        }
      });

      this.setState(AuthState.SignIn);
    } catch (err) {
      this.setError(err);
    }

    return;
  };

  public getAccessToken = async () => {
    if (Cookies.get("accessToken") && this.tokenExpired) {
      await this.refreshToken();
    }

    return Cookies.get("accessToken");
  };

  public removeTokens = () => {
    this.removeAccessToken();
    this.removeRefreshToken();
    this.removeTokenExpiration();
  };

  public setAccessToken = (token: string, expire: string) => {
    Cookies.set("accessToken", token);
    Cookies.set("accessTokenExpire", expire);
  };

  public get tokenExpired() {
    return !Cookies.get("accessTokenExpire") || parseInt(Cookies.get("accessTokenExpire") || "0") <= Date.now() / 1000;
  }

  public removeAccessToken = () => {
    Cookies.remove("accessToken");
  };

  public setRefreshToken = (token: string) => {
    Cookies.set("refreshToken", token);
  };

  public removeRefreshToken = () => {
    Cookies.remove("refreshToken");
  };

  public removeTokenExpiration = () => {
    Cookies.remove("accessTokenExpire");
  };
}

export const AuthContext = React.createContext({
  state: AuthState.SignedOut,
  user: undefined,
  error: undefined
});

export const Auth = new AuthService();
