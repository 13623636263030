import React from 'react';
import { makeStyles, Link, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    lineHeight: '2em',
  },
  link: {
    color: theme.palette.secondary.contrastText,
    fontSize: '1em',
    fontWeight: 300,
  },
}));

interface OwnProps {}

export const NavProjecten: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <nav className={classes.nav}>
      <Typography gutterBottom variant={'h5'}>
        Projecten
      </Typography>

      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/projecten/campagne-nederland/'}
        target={'_blank'}
      >
        Campagne Nederland
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/projecten/campagne-duitsland/'}
        target={'_blank'}
      >
        Campagne Duitsland
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/enschede-promotie/het-team/'}
        target={'_blank'}
      >
        Corona initiatieven
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/projecten/project-talent/'}
        target={'_blank'}
      >
        Project Talent
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/projecten/project-china/'}
        target={'_blank'}
      >
        Project China
      </Link>
    </nav>
  );
};
