import { Theme } from "@material-ui/core";

function getTypography(theme: Theme) {
  const typography = {
    fontFamily: "TradeGothicLT, sans-serif",

    body1: {
      fontSize: "1em",
      fontFamily: "TradeGothicLT, sans-serif"
    },
    body2: {
      fontFamily: "TradeGothicLT, sans-serif"
    },
    h2: {
      fontWeight: 400,
      fontFamily: "'TradeGothicLT-BoldCondTwenty', sans-serif",
      color: theme.palette.primary.main,
      textTransform: "uppercase"
    },
    h3: {
      fontWeight: 400,
      fontFamily: "'TradeGothicLT-BoldCondTwenty', sans-serif",
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      fontSize: "2em",
      letterSpacing: "-0.5px",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.8em"
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5em"
      }
    },
    h5: {
      fontWeight: 400,
      fontFamily: "'TradeGothicLT-BoldCondTwenty', sans-serif",
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      fontSize: "1.4em"
    }
  };

  for (const [key, value] of Object.entries(typography)) {
    theme.typography[key] = value;
  }
}

export default getTypography;
